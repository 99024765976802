'use client';

import React from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import JsonLd from '@src/components/Shared/JsonLd';
import NextImage from '@src/components/Shared/NextImage';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import InspectorWrapper from '@src/components/Shared/InspectorWrapper';
import TrustedbyStats from '@src/components/Sections/TrustedbyStats';
import FeaturedGrid from '@src/components/Sections/FeatureGrid';
import ImageTextSecondary from '@src/components/Sections/ImageTextSecondary';
import Testimonials from '@src/components/Sections/Testimonials';
import ServiceOverview from '@src/components/Sections/ServiceOverview';
import CallToAction from '@src/components/Sections/CallToAction';
import usePreviewContent from '@src/hooks/usePreviewContent';
import constructURL from '@src/utils/constructURL';
import { HomePageClientPropTypes } from './HomePage.types';
import HeroPrimary from '@src/components/Shared/HeroPrimary';
const HomePageClient: React.FC<HomePageClientPropTypes> = ({
  data
}) => {
  // Enable live updates for the post
  const page = usePreviewContent({
    content: data?.page
  });

  // Set the page view path for analytics tracking
  const {
    setPathPageView,
    setContentType
  } = useTrackingCode();
  setPathPageView(constructURL({
    languageCode: page?.languageCode,
    slug: '/',
    baseURL: ''
  }));
  setContentType('site-page');
  const homeJsonLd = JsonLd({
    name: page?.pageName,
    pageUrl: `/${page?.languageCode?.toLowerCase()}${page?.slug === '/' ? '' : page?.slug}`,
    thumbnailUrl: page?.seoMetadata?.previewImage?.url,
    description: page?.seoMetadata?.description,
    datePublished: page?.sys?.firstPublishedAt,
    dateModified: page?.sys?.publishedAt,
    locale: page?.languageCode?.toLowerCase()
  });
  return <>
      <script type='application/ld+json' dangerouslySetInnerHTML={{
      __html: JSON.stringify(homeJsonLd)
    }} />

      <HeroPrimary id={page?.hero?.items?.[0]?.scrollTo?.slice(1)} background='bg-[#070707]' attr='hero-component' className='mb-[8rem] flex flex-col items-center justify-between !overflow-visible !bg-background-primary pb-[3rem] pt-[6rem] md:flex-row md:!px-[4rem] md:pb-[6.3rem] md:pt-[9.3rem] lg:gap-[2rem] lg:pb-[8.2rem] lg:pt-[11.2rem] 2xl:!h-[75rem] 2xl:py-[9.6rem]' title={page?.hero?.items?.[0]?.title} bodyCopy={page?.hero?.items?.[0]?.bodyCopy} image={page?.hero?.items?.[0]?.heroImage} titleClassName='text-[4rem] lg:text-[6rem] 2xl:tracking-[-0.2432rem] leading-[4.7rem] lg:leading-[6.9rem] md:max-w-[36.6rem] md:max-w-[48rem] lg:max-w-[50rem] 2xl:max-w-[100%] 2xl:tracking-[-0.152rem]' imgClassName='overflow-hidden !absolute sm:!relative sm:!overflow-visible flex items-end md:items-center justify-end xl:max-h-[80%] top-[0] h-[100%] md:w-[50%] opacity-[0.2] sm:opacity-[1] [&>img]:!w-auto [&>img]:md:!max-w-[110%] [&>img]:md:right-[-10%] &>img]:lg:right-[0%] [&>img]:md:!absolute' bodyCopyClassName='[&>p]:text-[1.6rem] [&>p]:md:text-[1.8rem] [&>p]:lg:text-[2rem] [&>p]:2xl:text-[2rem] [&>p]:leading-[150%] md:max-w-[34.2rem] md:max-w-[43.7rem] lg:max-w-[51.7rem] 2xl:max-w-[59rem] relative md:top-[0] mt-[1.6rem]' childrenClassName='xsm:max-w-[100%]' data-sentry-element="HeroPrimary" data-sentry-source-file="HomePage.client.tsx" />

      <Container id={page?.forEveryTeam?.items?.[0]?.scrollTo?.slice(1)} className='py-[2rem] md:py-[4rem] 2xl:py-[6rem]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <SectionHeader entryId={page?.forEveryTeam?.items?.[0]?.sys?.id} preTitleClassName='mb-[1.6rem]' title={page?.forEveryTeam?.items?.[0]?.title} titleClassName='text-left md:text-center mt-[0] mb-[4rem] md:my-[4rem] text-[2.2rem] leading-[2.6rem] tracking-[-1px] font-[300] text-[1.8rem] md:leading-[3rem] md:text-[2.8rem] lg:max-w-[90rem] 2xl:max-w-[100rem] lg:text-[4.8rem] 2xl:text-[6.2rem] lg:leading-[5rem] 2xl:leading-[6.5rem]' childrenClassName='relative' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <RenderBodyCopy entryId={page?.hero?.items?.[0]?.sys?.id} className='mx-auto max-w-[51.7rem] text-left md:text-center 2xl:max-w-[78.2rem]' bodyCopy={page?.forEveryTeam?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
        </SectionHeader>
        {/* TODO: Change this entry from Duplex To SectionHeader */}
      </Container>

      <TrustedbyStats data={page?.trustedBy?.items?.[0]} data-sentry-element="TrustedbyStats" data-sentry-source-file="HomePage.client.tsx" />

      <Container id={page?.siteOptions?.items?.[0]?.scrollTo?.slice(1)} className='mt-[8rem] py-[2rem] pt-[0rem] md:mt-[4rem] md:py-[4rem] xl:mt-[0] 2xl:py-[6rem]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <div className='grid md:mb-[0] md:grid-cols-4 lg:mx-auto lg:max-w-[130rem] lg:gap-[2.4rem]'>
          {data?.page?.siteOptions?.items?.[0]?.topicCollection?.items?.map((site, index) => {
          return <div key={`${site?.sys?.id}-site-option`} className={`relative mb-[4rem] flex flex-col text-left md:mb-[0rem] md:block md:text-center ${index % 2 === 0 ? ' md:bottom-[3rem]' : 'md:top-[3rem]'}`}>
                {index % 2 !== 0 && <NextImage entryId={site.sys?.id} className='mx-auto mb-[2.4rem] w-full max-w-[22.6rem]' src={site.mediaPrimary?.url} alt='A portrait' aspectRatio={site.mediaPrimary?.width / site.mediaPrimary?.height} width={site.mediaPrimary?.width} />}

                <InspectorWrapper entryId={site.sys?.id} fieldId='title'>
                  <p className='order-1 mb-[1.6rem] font-[500] text-primary 2xl:text-[2.8rem] 2xl:leading-[4.2rem]'>
                    {site?.title}
                  </p>
                </InspectorWrapper>

                <RenderBodyCopy entryId={site.sys?.id} className='order-2 font-[300] text-body-copy' bodyCopy={site?.bodyCopy?.json?.content} />

                <RenderButtons items={site?.buttonCollection?.items} className='order-3 md:[&>div]:w-[100%]' btnClassName='!justify-start md:!justify-center' />

                {index % 2 === 0 && <NextImage entryId={site.sys?.id} className='order-0 mx-auto mb-[2.4rem] w-full max-w-[22.6rem] md:mb-[0rem] md:mt-[2.4rem]' src={site.mediaPrimary?.url} alt='A portrait' aspectRatio={site.mediaPrimary?.width / site.mediaPrimary?.height} width={site.mediaPrimary?.width} />}
              </div>;
        })}
        </div>
      </Container>

      <Container id={page?.strengthInNumbers?.items?.[0]?.scrollTo?.slice(1)} background='bg-background-primary' className='py-[5rem] md:py-[9rem]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <SectionHeader className='mb-[2.4rem] h-[100%] md:mb-[3.6rem]' entryId={page?.strengthInNumbers?.items?.[0]?.sys?.id} pretitle={page?.strengthInNumbers?.items?.[0]?.preTitle} title={page?.strengthInNumbers?.items?.[0]?.title} titleClassName='text-left md:text-center text-[2.2rem] leading-[2.6rem] tracking-[-0.0418] text-body-copy--light font-[300] text-[1.8rem] md:leading-[3rem] md:text-[2.8rem] lg:max-w-[90rem] 2xl:max-w-[78rem] lg:text-[4.8rem] 2xl:text-[6.2rem] lg:leading-[5rem] 2xl:leading-[7.56rem]' childrenClassName='relative' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <RenderBodyCopy entryId={page?.strengthInNumbers?.items?.[0]?.sys?.id} className='mx-auto mt-[2.4rem] max-w-[51.7rem] text-left font-[300] text-body-copy--light md:text-center 2xl:mt-[2rem] 2xl:max-w-[63rem]' bodyCopy={page?.strengthInNumbers?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
        </SectionHeader>

        <NextImage className='mx-auto max-w-[120rem]' entryId={page?.strengthInNumbers?.items?.[0]?.sys?.id} src={page?.strengthInNumbers?.items?.[0]?.mediaPrimary?.url} alt='A portrait' aspectRatio={page?.strengthInNumbers?.items?.[0]?.mediaPrimary?.width / page?.strengthInNumbers?.items?.[0]?.mediaPrimary?.height} width={page?.strengthInNumbers?.items?.[0]?.mediaPrimary?.width} data-sentry-element="NextImage" data-sentry-source-file="HomePage.client.tsx" />
      </Container>

      <Container attr='solution-header-component' id={page?.features?.items?.[0]?.scrollTo?.slice(1)} className='!mb-[3.6rem]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <SectionHeader entryId={page?.features?.items?.[0]?.sys?.id} pretitle={page?.features?.items?.[0]?.preTitle} title={page?.features?.items?.[0]?.title} titleClassName='text-[2.2rem] leading-[2.6rem] !mb-[1.8rem] md:leading-[4.6rem] md:text-[2.8rem] md:leading-[1] lg:text-[4.8rem] lg:tracking-[-0.096rem] lg:mb-[3.2rem] 2xl:leading-[6.4rem] 2xl:text-[6.2rem] 2xl:tracking-[-0.072rem] 2xl:!mb-[2rem]' childrenClassName='text-[1.6rem] leading-[2.2rem] md:leading-[2.4rem] lg:max-w-[74.6rem]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <>
            <RenderBodyCopy entryId={page?.features?.items?.[0]?.sys?.id} bodyCopy={page?.features?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
            <RenderButtons items={page?.features?.items?.[0]?.buttonCollection?.items} className='justify-center' data-sentry-element="RenderButtons" data-sentry-source-file="HomePage.client.tsx" />
          </>
        </SectionHeader>
      </Container>

      <FeaturedGrid id={page?.features?.items?.[0]?.scrollTo?.slice(1)} features={page?.features?.items?.[0]?.topicCollection?.items} data-sentry-element="FeaturedGrid" data-sentry-source-file="HomePage.client.tsx" />

      <Container id={page?.inOnePlace?.items?.[0]?.scrollTo?.slice(1)} attr='full-feature-component' className='flex flex-col' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
        <SectionHeader className='justify-center lg:mb-[3.4rem] lg:pb-[unset]' entryId={page?.inOnePlace?.items?.[0]?.sys?.id} pretitle={page?.inOnePlace?.items?.[0]?.preTitle} title={page?.inOnePlace?.items?.[0]?.title} titleClassName='text-[2.8rem] xl:text-[4.8rem] mb-[2.4rem] leading-[3.2rem] md:leading-[4.6rem] xl:leading-[5.4rem] font-[300] 2xl:max-w-[100rem] ' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
          <>
            <RenderBodyCopy entryId={page?.inOnePlace?.items?.[0]?.sys?.id} bodyCopy={page?.inOnePlace?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
            <RenderButtons items={page?.inOnePlace?.items?.[0]?.buttonCollection?.items} className='justify-center' data-sentry-element="RenderButtons" data-sentry-source-file="HomePage.client.tsx" />
          </>
        </SectionHeader>

        <NextImage className='order-[-1] mx-auto mb-[2.8rem] max-w-[120rem] lg:order-[1] lg:mb-[0]' objectFit='contain' entryId={page?.inOnePlace?.items?.[0]?.sys?.id} src={page?.inOnePlace?.items?.[0]?.mediaPrimary?.url} alt={page?.inOnePlace?.items?.[0]?.mediaPrimary?.description} aspectRatio={page?.inOnePlace?.items?.[0]?.mediaPrimary?.width / page?.inOnePlace?.items?.[0]?.mediaPrimary?.height} width={page?.inOnePlace?.items?.[0]?.mediaPrimary?.width} data-sentry-element="NextImage" data-sentry-source-file="HomePage.client.tsx" />
      </Container>

      <ImageTextSecondary entryId={page?.normativeData?.items?.[0]?.sys?.id} id={page?.normativeData?.items?.[0]?.scrollTo?.slice(1)} reversed className='!overflow-visible !py-[0] lg:px-[4rem] lg:py-[2rem]' imgSecondClassName='lg:!w-[65%]' pretitle={page?.normativeData?.items?.[0]?.preTitle} title={page?.normativeData?.items?.[0]?.title} imgURL={page?.normativeData?.items?.[0]?.mediaPrimary?.url} objectFit='contain' childrenElements={<>
            <RenderBodyCopy entryId={page?.normativeData?.items?.[0]?.sys?.id} className='2xl:max-w-[78.2rem]' bodyCopy={page?.normativeData?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.normativeData?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HomePage.client.tsx" />

      <ImageTextSecondary entryId={page?.meetOurClients?.items?.[0]?.sys?.id} id={page?.meetOurClients?.items?.[0]?.scrollTo?.slice(1)} background='bg-background-tertiary' className='!overflow-visible' title={page?.meetOurClients?.items?.[0]?.title} imgURL={page?.meetOurClients?.items?.[0]?.mediaPrimary?.url} imgFirstClassName='mt-[-1rem] md:mt-[-2rem] lg:mt-[unset] test' childrenElements={<>
            <RenderBodyCopy entryId={page?.meetOurClients?.items?.[0]?.sys?.id} bodyCopy={page?.meetOurClients?.items?.[0]?.bodyCopy?.json?.content} className='mb-[2.4rem] max-w-[60rem] [&>p]:2xl:text-[2.2rem] [&>p]:2xl:leading-[3.3rem]' />

            <div className='gap-4 mb-[2.4rem] grid max-w-[60rem] grid-cols-2 gap-y-[2.4rem] lg:mb-[3rem] 2xl:mb-[4rem]'>
              {page?.meetOurClients?.items?.[0]?.extraAssetsCollection?.items?.map((asset, index) => {
          return <div key={`${asset?.sys?.id}-${index}`} className='space-y-[0.5rem]'>
                    <InspectorWrapper entryId={asset?.sys?.id} fieldId='title'>
                      <p className='text-[2.4rem] font-[500] leading-[3.6rem] tracking-[-0.0264rem] text-primary lg:text-[4.8rem] lg:leading-[5.4rem] lg:tracking-[-0.0396rem]'>
                        {asset?.title}
                      </p>
                    </InspectorWrapper>

                    <RenderBodyCopy entryId={asset?.sys?.id} bodyCopy={asset.bodyCopy?.json?.content} className='text-[1.2rem] leading-[-0.0132rem] tracking-[-0.0132] lg:text-[1.6rem]' />
                  </div>;
        })}
            </div>
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HomePage.client.tsx" />

      <Testimonials id={page?.testimonials?.items?.[0]?.scrollTo?.slice(1)} testimonials={page?.testimonials?.items?.[0]?.topicCollection?.items} data-sentry-element="Testimonials" data-sentry-source-file="HomePage.client.tsx" />

      <NextImage id={page?.weExist?.items?.[0]?.scrollTo?.slice(1)} src={page?.weExist?.items?.[0]?.mediaPrimary?.url} entryId={page?.weExist?.items?.[0]?.sys?.id} alt='A portrait' className='mb-[4.5rem] h-[40rem] md:h-[45rem] lg:h-[54.3rem] [&>img]:object-top' data-sentry-element="NextImage" data-sentry-source-file="HomePage.client.tsx">
        <Container className='!mb-[0] h-[100%]' data-sentry-element="Container" data-sentry-source-file="HomePage.client.tsx">
          <SectionHeader entryId={page?.weExist?.items?.[0]?.sys?.id} className='h-[100%]' preTitleClassName='mb-[1.6rem]' title={page?.weExist?.items?.[0]?.title} titleClassName='text-left text-[2.2rem] w-[100%] leading-[2.6rem] tracking-[-0.0418] text-body-copy--light font-[300] text-[1.8rem] sm:text-center md:leading-[3rem] md:text-[2.8rem] lg:max-w-[55rem] 2xl:max-w-[78rem] 2xl:locale-es:max-w-[81rem] lg:text-[4.8rem] 2xl:text-[7.2rem] lg:leading-[5rem] 2xl:leading-[7.56rem]' childrenClassName='relative w-[100%]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HomePage.client.tsx">
            <RenderBodyCopy entryId={page?.weExist?.items?.[0]?.sys?.id} className='mx-auto mt-[2.4rem] max-w-[51.7rem] text-left text-body-copy--light sm:text-center  2xl:mt-[3.2rem] 2xl:max-w-[78.2rem]' bodyCopy={page?.weExist?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HomePage.client.tsx" />
            <RenderButtons className='justify-center' items={page?.weExist?.items?.[0]?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="HomePage.client.tsx" />
          </SectionHeader>
        </Container>
      </NextImage>

      <ServiceOverview entryId={page?.clientSuccess?.items?.[0]?.sys?.id} id={page?.clientSuccess?.items?.[0]?.scrollTo?.slice(1)} description={page?.clientSuccess?.items?.[0]?.bodyCopy} services={page?.clientSuccess?.items?.[0]?.topicCollection?.items} data-sentry-element="ServiceOverview" data-sentry-source-file="HomePage.client.tsx" />

      <CallToAction entryId={page?.callToAction?.items?.[0]?.sys?.id} attr='call-to-action-component' id={page?.callToAction?.items?.[0]?.scrollTo?.slice(1)} title={page?.callToAction?.items?.[0]?.title} bodyCopy={page?.callToAction?.items?.[0]?.bodyCopy?.json?.content} buttons={page?.callToAction?.items?.[0]?.buttonCollection?.items} data-sentry-element="CallToAction" data-sentry-source-file="HomePage.client.tsx" />
    </>;
};
export default HomePageClient;