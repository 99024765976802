import React from 'react';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import NextImage from '@src/components/Shared/NextImage';
import { FeatureGridPropTypes } from './FeatureGrid.types';
import { FeatureTypes } from '@src/typescriptGlobals/contentful.types';
const FeaturedGrid: React.FC<FeatureGridPropTypes> = ({
  attr,
  features,
  id
}) => {
  return <Container id={id} attr={attr} className='mt-[8rem]' data-sentry-element="Container" data-sentry-component="FeaturedGrid" data-sentry-source-file="FeatureGrid.tsx">
      <div className='grid-flow-col grid-rows-2 gap-y-[2.4rem] md:mb-[0] md:grid md:gap-[1.6rem] md:gap-y-[3.2rem] lg:mx-auto lg:max-w-[107.2rem] lg:gap-[2.4rem] 2xl:!gap-[5.7rem] 2xl:!gap-y-[3.6rem]'>
        {features?.map((product: FeatureTypes, index) => {
        return <div key={`${product?.sys?.id}-vald-technology`} className={`last:mt-auto ${index === 0 ? ' row-span-2' : 'row-span-1'}`}>
              <SectionHeader entryId={product?.sys?.id} titleClassName={`${index === 0 ? '' : 'md:!text-right'} text-[1.8rem] [&>span]:font-[600] font-[400] tracking-[-0.028rem] text-left lg:text-[2rem] lg:leading-[2.1rem] lg:tracking-[-0.04rem] 2xl:text-[2.2rem] 2xl:leading-[2.31rem] 2xl:tracking-[-0.044rem]`} className={`mb-[2.4rem] ${index === 0 ? 'mt-[0rem]' : 'mt-[3rem] text-right md:mt-[0rem]'}`} title={product.title}></SectionHeader>

              <div className={`${index === 0 ? '' : 'md:!text-right'} text-[1.6rem] leading-[1.5] sm:max-w-[33rem] md:mx-[unset] md:max-w-[unset] md:text-left lg:text-[1.4rem] lg:leading-[2.1rem] 2xl:text-[1.6rem] 2xl:leading-[2.4rem]`}>
                <RenderBodyCopy entryId={product?.sys?.id} bodyCopy={product.bodyCopy?.json?.content} />
              </div>
              <NextImage className='mt-[2.4rem] rounded-[2rem] bg-[#F9F9F9]' entryId={product?.sys?.id} src={product?.mediaPrimary?.url} alt={product?.mediaPrimary?.description ?? ''} aspectRatio={product?.mediaPrimary?.width / product?.mediaPrimary?.height} width={product?.mediaPrimary?.width} />
            </div>;
      })}
      </div>
    </Container>;
};
export default FeaturedGrid;