import { JsonLdPropTypes } from './JsonLd.types';
const JsonLd = ({
  pageUrl,
  name,
  thumbnailUrl,
  datePublished,
  dateModified,
  description,
  locale
}: JsonLdPropTypes) => {
  const todayDate = new Date();
  return {
    '@context': 'https://schema.org',
    '@graph': [{
      '@type': 'WebPage',
      '@id': `https://valdperformance.com${pageUrl ?? '/'}`,
      url: `https://valdperformance.com${pageUrl ?? '/'}`,
      name: name ?? 'Vald Performance',
      isPartOf: {
        '@id': `https://valdperformance.com/${locale}/#website`
      },
      about: {
        '@id': `https://valdperformance.com/${locale}/#organization`
      },
      primaryImageOfPage: {
        '@id': `https://valdperformance.com/${locale}/#primaryimage`
      },
      image: {
        '@id': `https://valdperformance.com/${locale}/#primaryimage`
      },
      thumbnailUrl,
      datePublished: datePublished ?? todayDate,
      dateModified: dateModified ?? todayDate,
      description: description ?? 'Vald Performance',
      inLanguage: 'en-US',
      potentialAction: [{
        '@type': 'ReadAction',
        target: [`https://valdPerformance.com/${locale}`]
      }]
    }, {
      '@type': 'ImageObject',
      inLanguage: 'en-US',
      '@id': `https://valdperformance.com/${locale}/#primaryimage`,
      url: thumbnailUrl ?? '',
      contentUrl: thumbnailUrl ?? '',
      width: 1000,
      height: 800
    }, {
      '@type': 'WebSite',
      '@id': `https://valdperformance.com/${locale}/#website`,
      url: `https://valdperformance.com/${locale}`,
      name: 'VALD Performance | Human Measurement Technologies',
      description: 'Move. Measure. Modify',
      publisher: {
        '@id': `https://valdperformance.com/${locale}/#organization`
      },
      inLanguage: 'en-US'
    }, {
      '@type': 'Organization',
      '@id': `https://valdperformance.com/${locale}/#organization`,
      name: 'VALD Performance',
      url: `https://valdperformance.com/${locale}`,
      logo: {
        '@type': 'ImageObject',
        inLanguage: 'en-US',
        '@id': `https://valdperformance.com/${locale}/#/schema/logo/image/`,
        url: '',
        contentUrl: '',
        caption: 'VALD Performance'
      },
      image: {
        '@id': `https://valdperformance.com/${locale}/#/schema/logo/image/`
      }
    }]
  };
};
export default JsonLd;